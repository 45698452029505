import React from 'react'
import { css } from '@emotion/react'
import { Component } from 'react';
// import PropTypes from 'prop-types';
import {
    Container,
    Typography,
    RadioGroup,
    CircularProgress,
    Backdrop,
    Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
    TitleIcon,
    CardElevation,
    ButtonDegrade,
    ModalInfo,
} from '@findep/mf-landings-core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { isEqual } from 'lodash'

import MultiLineRadio from '../../forms/shared/MultiLineRadio'
import CONSTANTS from '../../../helpers/preOfertaConstants'
import formatNumber from '../../../helpers/formatNumber'
import { getPeriodPlural } from '../../../helpers/period'
import { OfferService } from '../../../services/LandingV4/offer'
import { OfferUpdateService } from '../../../services/LandingV4/offerUpdate'
import { navigate } from "../../../helpers/queryNavigate"


import { withThemeProps } from '@findep/microfronts-core'

import {
    getMessageClabe,
    getSeverityClabe,
} from '../../../helpers/validateClabe'

const fullWidth = css`
        width: 100%;
    `
const paddingXText = css`
    padding-left: 1em;
    padding-right: 1em;
`

const buttonCss = css`
    display: flex;
    justify-content: center;
`




const backDropCss = css`
    z-index: 1000;
`
let Offer = []
let OfferUpdate = []

class PreOferta extends Component {

    constructor(props) {
        super(props)

        this.state = {
            seguroSeleccionado: '',
            dispersionSeleccionada: '',
            modal: '',
            seguros: [],
            oferta: '',
            dispersion: CONSTANTS.SPEI,
            clabe: '',
            ofertas: [],
            buttonEnabled: false,
            loading: false,
            errorService: false,
            clabeResult: '',
            flujo: null,
            tarjeta_credito: 'S',
            act_radio: '',
            displayDebitCard: false,
            _errors: {
                clabe: {
                    error: false,
                    errorType: 'Número inválido',
                }
            }
        }

        this.onChangeCuenta = this.onChangeCuenta.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this._handleError = this._handleError.bind(this)
        this.submit = this.submit.bind(this)
        this.calculateOffers = this.calculateOffers.bind(this)
        this.getAcceptedInsuranceIds = this.getAcceptedInsuranceIds.bind(this)

    }

    saveGtmcolor(color){
        let puntaje
        switch (color){
          case 'BLUE':  puntaje='950'; break;
          case 'GREEN':  puntaje='950'; break;
          case 'YELLOW':  puntaje='300'; break;
          case 'ORANGE':  puntaje='250'; break;
          case 'GRAY_1':  puntaje='100'; break;
          case 'GRAY_2':  puntaje='0'; break;
          case 'RED':  puntaje='0'; break;
          default : puntaje='0'; break;
    
        }
        console.log("puntaje=>",puntaje)
        window.dataLayer.push({ 
                  'event': 'score', 
                  'color': `${color}`, 
                  'valorColor': `${puntaje}`
                });
      }

    componentDidMount() {
        const flujo = JSON.parse(sessionStorage.getItem('flujo'))

        if (flujo?.datosAdicionales) {
            const { insurance, offers,color } = flujo.datosAdicionales
            this.saveGtmcolor(color)
            this.setState({
                seguros: insurance,
                ofertas: offers,
                oferta: offers && offers.length > 0 ? offers[0].id : '',
                flujo
            })
            if (offers.length > 0) {
                this.setState({ buttonEnabled: true })
            }
            Offer = new OfferService(flujo.creditApplicationId)
        } else {
            this.setState({ flujo })
        }
        if (flujo?.covered === "Covered") {
            this.setState({ displayDebitCard: false, tarjeta_credito: 'N', buttonEnabled: true })
        } else {
            this.setState({ displayDebitCard: false, tarjeta_credito: 'S', buttonEnabled: true })
        }


    }


    async componentDidUpdate(prevProps, prevState) {

        const { dispersion, seguros } = this.state

        if (!isEqual(prevState.seguros, seguros) || dispersion != prevState.dispersion) {
            const prevIndex = prevState.ofertas.findIndex(element => element.id === prevState.oferta)
            try {
                await this.calculateOffers(prevIndex)
            } catch (e) {
                console.error(e)
                this.setState({ errorService: true })
            }
        }


    }


    async calculateOffers(prevIndex) {
        if (prevIndex < 0) {
            prevIndex = 0
        }
        const { flujo, dispersion } = this.state
        OfferUpdate = new OfferUpdateService(flujo.creditApplicationId)
        OfferUpdate.setDisbursementMethod(dispersion)
        OfferUpdate.setAcceptedInsuranceIds(this.getAcceptedInsuranceIds())

        const { data } = await OfferUpdate.send()
        let oferta
        if (data.length < prevIndex + 1) {
            oferta = data[data.length - 1].id
        } else {
            oferta = data[prevIndex].id
        }
        this.setState({ ofertas: data, oferta })
    }


    onChangeCuenta(data, confirmString = 'SI TENGO TARJETA') {
        if (data.value === confirmString) {
            this.setState({ tarjeta_credito: 'S' })
        }
        this.closeModal()
    }


    openCuentaBancaria() {
        this.setState({ modal: 'CUENTA' })
    }


    closeModal() {
        this.setState({ modal: '', seguroSeleccionado: '', dispersionSeleccionada: '' })
    }

    handleChange(prop, data) {
        const state = {
            [prop]: data.value
        }

        const tmp = Object.assign({}, this.state, state)

        this.setState(tmp, () => {


            //primer modulo que habilita los otros campos
            if (prop === 'tarjeta_credito') {
                if (data.value === 'N') {
                    this.openCuentaBancaria()
                    this.setState({ buttonEnabled: true, tarjeta_credito: 'N' })
                } else {
                    this.setState({ buttonEnabled: true, tarjeta_credito: 'S' })
                }
            }

        })
    }

    _handleChange(event,) {

        this._handleError(event.target.name, {}, false)
        this.setState({ oferta: event.target.value })
    }
    _handleError(prop, data, bool = true) {
        let _errors = { ...this.state._errors }

        _errors[prop] = {
            ..._errors[prop],
            error: bool
        }
        this.setState({ _errors })
    }

    getAcceptedInsuranceIds() {
        const { seguros } = this.state
        const acceptedInsuranceIds = seguros.filter(element => element.checked === true).map(element => element.id)
        return acceptedInsuranceIds
    }

    async submit() {
        let radioCard = this.state.tarjeta_credito === 'S' ? 'AfiCard' : 'AfiLegacy'
        const { dispersion, clabe, flujo, oferta, ofertas } = this.state
        Offer = new OfferService(flujo.creditApplicationId)
        this.setState({ buttonEnabled: false, loading: true })

        const ofertaObj = ofertas.find(element => element.id === oferta)
        const acceptedInsuranceIds = []
        Offer.setOfferId(oferta)
        Offer.setAcceptedInsuranceIds(acceptedInsuranceIds)
        Offer.setDisbursementMethod(radioCard)
        if (dispersion === CONSTANTS.SPEI) {
            Offer.setClabe("")
            Offer.setCardNumber("")
        }

        try {
            await Offer.send()
            sessionStorage.setItem('flujo', JSON.stringify({ ...flujo, preOferta: { oferta: ofertaObj } }))
            navigate(this.props.pageContext.next.path)
        } catch (e) {
            console.error(e)
            this.setState({ errorService: true ,loading: false})
        } finally {
            this.setState({ buttonEnabled: true  })
        }

    }

    render() {

        const { title,
            subtitle,
            cardOneTitle,
            nextButtonText,
            conditionsText,
            amountText,
            paymentText,
            durationText,
            offerTooltip,
            modalInfoBody,
            modalInfoBtnLeft,
            modalInfoBtnRight,
            errorMessage,
            lang,
            modalTitle
        } = this.props

        return (
            <Container justify="center" maxWidth="sm">
                <Backdrop open={this.state.loading} css={backDropCss}>
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert
                        variant="filled"
                        severity="warning"
                        onClose={() => this.setState({ errorService: false })}
                    >
                        {errorMessage}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={!!this.state.clabeResult}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ clabeResult: '' })}
                >
                    <Alert
                        variant="filled"
                        severity={getSeverityClabe(this.state.clabeResult)}
                        onClose={() => this.setState({ clabeResult: '' })}
                    >
                        {getMessageClabe(this.state.clabeResult)}
                    </Alert>
                </Snackbar>

                <ModalInfo
                    id={`tu-oferta-modal-cuenta`}
                    onClose={this.closeModal}
                    autoClose={false}
                    icon={CheckCircleIcon}
                    title={modalTitle}
                    color="warning"
                    open={this.state.modal === 'CUENTA'}
                    body={<>{modalInfoBody}</>}
                    onChange={(data) => this.onChangeCuenta(data)}
                    btnLeft={modalInfoBtnLeft}
                    btnRight={modalInfoBtnRight}
                />
                <Typography component="div" align="center">
                    {title}
                </Typography>

                <Typography css={paddingXText} align="center">
                    {subtitle}
                </Typography>
                <br />

                <CardElevation>
                    <TitleIcon icon={AttachMoneyIcon} title={cardOneTitle} />
                    <RadioGroup id="tu-oferta-tu-prestamo-group" aria-label="oferta" name="oferta" onChange={this._handleChange} css={fullWidth} value={`${this.state.oferta}`}>
                        {

                            this.state.ofertas.map((oferta, index) => (
                                <MultiLineRadio
                                    id={`tu-oferta-tu-prestamo-${index}`}
                                    name={`tu-oferta-tu-prestamo-${index}`}
                                    key={index}
                                    value={`${oferta.id}`}
                                    disabledText={this.state.oferta !== `${oferta.id}`}
                                    text={
                                        <>
                                            <Typography>
                                                {amountText}: {formatNumber(oferta.amount, 3, ',', '$', 0, '')}
                                            </Typography>
                                            <Typography>
                                                {durationText}: {`${oferta.paymentTerms} ${getPeriodPlural(oferta.paymentPeriod, lang)}`} <span style={{ fontSize: '14px' }}>({`${Math.round(((12 * oferta.paymentTerms) / 26))} ${getPeriodPlural(("MONTHLY"), lang)}`})</span>
                                            </Typography>
                                            <Typography>
                                                {paymentText}:  {formatNumber(oferta.payment, 3, ',', '$', 0, '')}
                                            </Typography>

                                        </>
                                    }
                                    tooltip={offerTooltip} />
                            ))
                        }
                    </RadioGroup>
                </CardElevation>
                <br />
                <CardElevation>
                    <div css={buttonCss} >
                        <ButtonDegrade
                            id="tu-oferta-continuar"
                            icon={!this.state.loading ? ArrowForwardIcon : null}
                            textButton={this.state.loading ? <CircularProgress size={30} color="primary" /> : nextButtonText}
                            onClick={this.submit}
                            disabled={!this.state.buttonEnabled}
                        />
                    </div>
                </CardElevation>
                <br />

                <br />

                <div css={[paddingXText]}>
                    <Typography variant="caption">
                        {conditionsText}
                    </Typography>
                </div>
                <br />

                <br />


            </Container>
        );
    }
}

PreOferta.propTypes = {

};

PreOferta.defaultProps = {
    title: (
        <>
            <Typography color="primary" variant="h5" display="inline">
                <strong>¡Felicidades!</strong><br />
            </Typography>
            <Typography color="secondary" variant="h5" display="inline">
                <strong> Has sido pre-calificado</strong><br /><br />
            </Typography>
        </>
    ),
    subtitle: 'Con base en la información que nos has proporcionado, Apoyo Financiero te ofrece las siguientes opciones de préstamo. Selecciona la que más te convenga.',
    cardOneTitle: 'Tu préstamo',
    cardTwoTitle: 'Tu préstamo',
    cardTwoSubtitle: 'Para tu comodidad, en caso de que tu préstamo sea autorizado puedes recibirlo en tu tarjeta de débito.',
    cardQuestion: '¿Tienes una tarjeta de débito VISA o Mastercard a tu nombre?',
    cardQuestionOptions: ['Si', 'No'],
    nextButtonText: 'CONTINUAR CON SOLICITUD',
    conditionsText: 'Condiciones: Aprobación final sujeta a la verificación de tu información personal y documentos presentados. ¡Estás muy cerca de obtener el apoyo que necesitas! Para avanzar selecciona “Continuar con solicitud”',
    amountText: 'Monto',
    paymentText: 'Pago',
    durationText: 'Durante',
    offerTooltip: (<>En caso de que tu préstamo sea <br /> aprobado, la cantidad autorizada <br /> puede ser mayor o menor al <br /> monto solicitado.</>),
    modalInfoBody: 'Con tu cuenta bancaria además de recibir la transferencia de tu préstamo, también puedes realizar tus pagos sin salir de casa. Ahorra tiempo y dinero',
    modalInfoBtnLeft: 'SI TENGO TARJETA',
    modalInfoBtnRight: 'CONTINUAR SIN TARJETA',
    errorMessage: 'Lo sentimos, por el momento el servicio no se encuentra disponible',
    lang: 'es',
    modalTitle: 'Recuerda'
}

export default withThemeProps(PreOferta, 'VDNPAfiPreOferta');